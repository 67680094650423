export default class Utils {
  static isHomeyPro(homey) {
    if (!homey) throw new Error(`Could not determine platform, expected homey, got: ${homey}`);
    return homey.platform === 'local'
  }

  static isHomeyCloud(homey) {
    if (!homey) throw new Error(`Could not determine platform, expected homey, got: ${homey}`);
    return homey.platform === 'cloud'
  }

  static isHomeyProPlatformV1(homey) {
    if (!homey) throw new Error(`Could not determine platform and version, expected homey, got: ${homey}`);
    return this.isHomeyPro(homey) && homey.platformVersion === 1
  }

  static isHomeyProPlatformV2(homey) {
    if (!homey) throw new Error(`Could not determine platform and version, expected homey, got: ${homey}`);
    return this.isHomeyPro(homey) && homey.platformVersion === 2
  }
}