import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import {
  withApi,
  withUser,
  withHomey,
  withMessages,
} from '../../services/AthomApi';
import ReactDOM from 'react-dom';
import semver from 'semver';

import Utils from '../../services/Utils';

import Page from '../Page';
import Card from '../Card';

import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from "@material-ui/core/Typography";
import Select from '@material-ui/core/Select';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import IconVerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';
import IconCopy from '@material-ui/icons/FileCopy';
import IconRefresh from '@material-ui/icons/Refresh';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ReactJson from 'react-json-view';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CircularProgress } from '@material-ui/core';

function buf2hex(buffer) {
  return Array.prototype.map.call(buffer, x => x.toString(16).padStart(2, '0')).join('');
}

const HW_REGIONS = {
  'AE': 'AE (Europe)',
  'AU': 'AU (US)',
  'AH': 'AH (Asia)',
};

const REGIONS_PRO_2023 = {
  'ANZ': 'Australia/New Zealand - 919.8 MHz / 921.4 MHz',
  'CN': 'China - 868.4 MHz',
  'EU': 'Europe - 868.4 MHz / 869.85 MHz',
  'HK': 'Hong Kong - 919.8 MHz',
  'IL': 'Israel - 916 MHz',
  'IN': 'India - 865.2 MHz',
  'JP': 'Japan - 922.5 MHz / 923.9 MHz / 926.3 MHz',
  'KR': 'Korea - 920.9 MHz / 921.7 MHz / 923.1 MHz',
  'RU': 'Russia - 869 MHz',
  'US': 'United States of America - 908.4 MHz / 916 MHz',
  'default': 'Reset to country default',
};


const REGIONS_CLOUD= {
  'Australia & New Zealand': 'Australia/New Zealand - 919.8 MHz / 921.4 MHz',
  'China': 'China - 868.4 MHz',
  'European Union': 'Europe - 868.4 MHz / 869.85 MHz',
  'Hong Kong': 'Hong Kong - 919.8 MHz',
  'Israel': 'Israel - 916 MHz',
  'India': 'India - 865.2 MHz',
  'Japan': 'Japan - 922.5 MHz / 923.9 MHz / 926.3 MHz',
  'South Korea': 'Korea - 920.9 MHz / 921.7 MHz / 923.1 MHz',
  'Russia': 'Russia - 869 MHz',
  'USA': 'United States of America - 908.4 MHz / 916 MHz',
  'default': 'Reset to country default',
};

// Prettify a view errors from ManagerZwave.runCommand()
function parseError(err) {
  if (err && typeof err.message === 'string') {
    switch (err.message) {
      case 'flashing':
        throw new Error('Currently updating the Z-Wave chip firmware. Please wait a few minutes and try again.');
      case 'backup':
        throw new Error('Z-Wave chip is being backed up. Please wait a few minutes and try again.');
      case 'restore':
        throw new Error('Z-Wave chip is restoring from backup. Please wait a few minutes and try again.');
    }
  }
  throw err;
}

class PageToolsZwave extends Component {

  constructor(props) {
    super(props);

    this._logContainer = null;
    this.state = {
      zw_state: null,
      zw_topology: null,
      zw_failed_nodes: [],
      zw_healing_nodes: {},
      zw_removing_nodes: {},
      zw_testing_nodes: {},

      log: [],
      logFilter: '',
      logEnabled: null,
      logAutoscroll: true,

      nvr: null,
      nodes: null,
      homeId: '',
      networkKeys: {
        S0: '',
        Unauthenticated: '',
        Authenticated: '',
        Access: '',
      },
      hardwareRegion: '',
      softwareRegion: '',
      currentCommand: '',

      rootNodeId: null,
      stats: {},
      changingRegion:'',
    }
  }

  componentDidMount() {
    this.props.homey.zwave
      .on('state', this._onZwaveState)
      .on('log', this._onZwaveLog)
      .connect()
      .catch(this.props.handleError);

    this._getState();
    this._getDevices();
    this._getTopology().then(() => {
      this._getFailedNodes();
    });

    // Only get ZWave logs when the platform is not Cloud.
    if (!Utils.isHomeyCloud(this.props.homey)) {
      this._getLog();
    }
  }

  componentWillUnmount() {
    if (this.props.homey) {
      this.props.homey.zwave.destroy();
    }
  }

  _getState = () => {
    this.props.homey.zwave
      .getState()
      .then(this._onZwaveState)
  }

  _getLog = () => {
    this.props.homey.zwave
      .getLog()
      .then(this._onZwaveLog)
  }

  _getTopology = () => {
    return this.props.homey.zwave
      .runCommand({
        $timeout: 60000,
        command: 'getNetworkTopology'
      })
      .then(zw_topology => {
        this.setState({
          zw_topology
        });
      })
      .catch(parseError)
      .catch(this.props.handleError)
  }

  _getFailedNodes = () => {
    return this.props.homey.zwave
      .runCommand({
        $timeout: 60000,
        command: 'getFailedNodes'
      })
      .then(zw_failed_nodes => {
        return this.setState({ zw_failed_nodes });
      })
      .catch(parseError)
      .catch(this.props.handleError)
  }

  _isFailedNode = async (nodeId) => {
    return this.props.homey.zwave
      .runCommand({
        $timeout: 60000,
        command: 'isFailedNode',
        opts: { nodeId },
      })
      .catch(parseError);
  }

  _testNode = (nodeId) => {

    const { zw_testing_nodes } = this.state;
    if (zw_testing_nodes[nodeId]) return;
    zw_testing_nodes[nodeId] = true;
    this.setState({ zw_testing_nodes });

    this._sendBasicGet(nodeId)
      .catch(() => { })
      .then(async () => {
        const isFailed = await this._isFailedNode(nodeId);
        const { zw_failed_nodes } = this.state;

        if (isFailed) {
          if (!zw_failed_nodes.includes(nodeId))
            zw_failed_nodes.push(nodeId);
          this.props.handleError(`Node ${nodeId} is unreachable`);
        } else {
          const index = zw_failed_nodes.indexOf(nodeId);
          if (index > -1)
            zw_failed_nodes.splice(index, 1);
          this.props.handleSuccess(`Node ${nodeId} is reachable`);
        }

        this.setState({ zw_failed_nodes });
      })
      .catch(this.props.handleError)
      .then(() => {
        const { zw_testing_nodes } = this.state;
        zw_testing_nodes[nodeId] = false;
        this.setState({ zw_testing_nodes });
      })
  }

  _getDevices = () => {
    this.props.homey.devices
      .getDevices()
      .then(result => {

        const secureNodes = {};
        const batteryNodes = [];

        for (let deviceId in result) {
          let device = result[deviceId];
          if (!Array.isArray(device.flags)) continue;
          if (device.flags.indexOf('zwave') === -1) continue;

          if (device.settings) {
            const nodeId = parseInt(device.settings.zw_node_id, 10);

            if (device.settings.zw_secure) {
              secureNodes[nodeId] = device.settings.zw_secure;

              if (device.settings.zw_secure === '✓') {
                secureNodes[nodeId] = 'S0';
              }
            }

            if (device.settings.zw_battery === '✓') {
              batteryNodes.push(nodeId);
            }
          }
        }

        this.setState({
          zw_battery_nodes: batteryNodes,
          zw_secure_nodes: secureNodes,
        })

      })
      .catch(this.props.handleError)
  }

  _onZwaveState = state => {
    const { zw_state } = state;
    const {
      nodes,
      logEnabled,
      networkKey,
      networkKeys,
      stats,
      homeId,
      nodeId: rootNodeId,
      currentCommand,
      hardwareRegion,
      softwareRegion,
    } = zw_state;

    this.setState({
      zw_state,
      logEnabled,
      nodes,
      stats,
      homeId,
      rootNodeId,
      currentCommand,
      hardwareRegion,
      softwareRegion,
    });

    if (networkKeys !== undefined) {
      this.setState({
        networkKeys: {
          S0: this._bufferToString(networkKeys.S0),
          Unauthenticated: this._bufferToString(networkKeys.Unauthenticated),
          Authenticated: this._bufferToString(networkKeys.Authenticated),
          Access: this._bufferToString(networkKeys.Access),
        }
      });
    } else {
      // support older versions of node-zwave which only have a S0 networkkey
      if (networkKey !== undefined) {
        this.setState({
          networkKeys: {
            S0: this._bufferToString(networkKey),
            Unauthenticated: '',
            Authenticated: '',
            Access: '',
          }
        });
      }
    }
  }

  _onZwaveLog = log => {
    this.setState({
      log: this.state.log.concat(log)
    });
  }

  _onToggleAutoscroll = () => {
    this.setState({
      logAutoscroll: !this.state.logAutoscroll,
    });

    this.props.handleSuccess((this.state.logAutoscroll ? 'Disabled' : 'Enabled') + ' autoscrolling');
  }

  _onFilterChange = event => {
    this.setState({
      logFilter: event.target.value.toLowerCase()
    });
  }

  _onSendNIF = e => {
    this.props.homey
      .zwave
      .runCommand({
        command: 'sendNodeInformationFrame'
      })
      .then(this.props.handleSuccess)
      .catch(parseError)
      .catch(this.props.handleError)
  }

  _onSendTestFrame = nodeId => {
    this.props.homey
      .zwave
      .runCommand({
        $timeout: 15000,
        command: 'sendTestFrame',
        opts: { nodeId },
      })
      .then(this.props.handleSuccess)
      .catch(parseError)
      .catch(this.props.handleError)
  }

  _onSendRawData = () => {
    this._sendRawData(this.state.sendRawData)
      .then(this.props.handleSuccess)
      .catch(this.props.handleError)

    this.setState({
      sendRawData: ''
    });
  }

  _onFactoryReset = () => {
    if (!confirm('This will reset the Z-Wave network. All Z-Wave devices will be deleted. Do you want to continue?')) return;

    return this.props.homey
      .zwave
      .runCommand({
        $validate: false,
        command: 'factoryReset',
      })
      .catch(parseError)
  }

  _sendRawData = value => {
    return this.props.homey
      .zwave
      .runCommand({
        $validate: false,
        command: 'sendData',
        opts: value,
      })
      .catch(parseError)
  }

  _onSendRawDataChange = event => {
    this.setState({
      sendRawData: event.target.value,
    });
  }

  _onSetLogEnabled = e => {
    this.props.homey
      .zwave
      .setLogEnabled({
        enabled: !this.state.logEnabled
      })
      .catch(this.props.handleError)
  }

  _sendBasicGet = async (nodeId) => {
    return this._sendRawData(`${nodeId},0x20,0x02`);
  }

  _sendBasicSet = async (nodeId, value) => {
    return this._sendRawData(`${nodeId},0x20,0x01,${value}`);
  }

  _onSendBasicGet = async (nodeId) => {
    return this._sendBasicGet(nodeId)
      .then(this.props.handleSuccess)
      .catch(this.props.handleError)
  }

  _onSendBasicSet = (nodeId, value) => {
    this._sendBasicSet(nodeId, value)
      .then(this.props.handleSuccess)
      .catch(this.props.handleError)
  }

  _onHeal = (nodeId) => {
    const { zw_healing_nodes } = this.state;
    zw_healing_nodes[nodeId] = true;
    this.setState({ zw_healing_nodes });

    this.props.homey
      .zwave
      .runCommand({
        $timeout: 30000,
        command: 'heal',
        opts: {
          nodeId: parseInt(nodeId, 10)
        }
      })
      .then(this.props.handleSuccess)
      .catch(parseError)
      .catch(this.props.handleError)
      .then(() => {
        const { zw_healing_nodes } = this.state;
        zw_healing_nodes[nodeId] = false;
        this.setState({ zw_healing_nodes });
      })
  }

  _onRemove = (nodeId) => {
    nodeId = parseInt(nodeId, 10);

    const { zw_removing_nodes } = this.state;
    if (zw_removing_nodes[nodeId]) return;
    zw_removing_nodes[nodeId] = true;
    this.setState({ zw_removing_nodes });

    this.props.homey.zwave.runCommand({
      command: 'removeFailedNode',
      opts: { nodeId },
    })
    .catch(parseError)
    .catch(this.props.handleError)
    .then(() => {
      const { zw_removing_nodes } = this.state;
      zw_removing_nodes[nodeId] = false;
      this.setState({ zw_removing_nodes });
    });
  }

  _onRemoveGeneric = () => {
    this.props.homey.zwave.runCommand({
      $timeout: 60000,
      command: 'removeNode',
    }).then(nodeId => {
      if (nodeId === null)
        return this.props.handleSuccess(`An unknown node was removed.`);
      this.props.handleSuccess(`Node ${nodeId} removed.`);
    })
    .catch(parseError)
    .catch(err => {
      if (err.name === 'aborted') return;
      this.props.handleError(err);
    })
  }

  _onRemoveUnknownNodes = () => {
    this.props.homey.zwave.runCommand({
      $timeout: 60000, // It takes a while to communicate with all nodes and determine if removal succeeded
      command: 'removeUnknownNodes',
    }).then(removedNodeIds => {
      if (removedNodeIds && removedNodeIds.length > 0) {
        this.props.handleSuccess(`Removed ${removedNodeIds.length} unknown nodes (${removedNodeIds.join(', ')}).`);
      } else {
        this.props.handleSuccess(`No unknown nodes were removed.`);
      }
    })
    .catch(parseError)
    .catch(err => {
      this.props.handleError(err);
    })
  }

  _onRemoveGenericAbort = () => {
    this.props.homey.zwave.runCommand({
      command: 'removeNodeAbort',
    }).then(() => {
      this.props.handleSuccess('Cancelled');
    })
    .catch(parseError)
    .catch(this.props.handleError);

  }

  handleChangeRegionDialogClose() {
    this.setState({ changingRegion: '' });
  }

  handleChangeRegionDialogConfirm() {
    let value = this.state.changingRegion;
    if (this.state.changingRegion === 'default') {
      value ='';
    }
    this.props.homey.zwave.setOptionRegionOverride({value}).then(() => {
      this.props.handleSuccess(`Z-Wave region changed to ${this.state.changingRegion}`);
      this.props.homey.system.reboot();
      this.setState({ changingRegion: '' });
    })
    .catch((err) => {
      if (err.message.includes('Invalid Endpoint')){
        err.message='Please update your Homey to the latest version to use this feature.';
      }
      this.props.handleError(err);
      this.setState({ changingRegion: '' });
    });
  }

  handleChangeRegionSelect(event) {
    this.setState({ changingRegion: event.target.value });
  }

  _getNodeColor = nodeId => {
    return `hsl(${Math.floor(this.state.nodes.indexOf(nodeId) / this.state.nodes.length * 360)}, 100%, 75%)`;
  }

  _bufferToString = (buffer) => {
    if (!buffer) return '';
    if (typeof buffer === 'string') return buffer;
    if (buffer instanceof Uint8Array) return buf2hex(buffer);
    if (buffer instanceof ArrayBuffer) return buf2hex(new Uint8Array(buffer));
    if (buffer.type === 'Buffer') return buf2hex(new Uint8Array(buffer.data));
    console.error(`unable to turn buffer into string`, buffer);
    return '';
  }

  componentDidUpdate() {
    const { logAutoscroll } = this.state;
    // scroll logs to bottom
    if (logAutoscroll) {
      const el = ReactDOM.findDOMNode(this._logContainer);
      if (el) el.scrollTop = el.scrollHeight;
    }
  }

  render() {
    const {
      nodes,
      stats,
      noAckNodes,
      priorityNodeId,
      hoverNodeId,
      rootNodeId,
      homeId,
      networkKeys,
      currentCommand,
      hardwareRegion,
      softwareRegion,
      zw_state,
      zw_topology,
      zw_battery_nodes,

      log,
      logEnabled,
      logAutoscroll,
      logFilter,

      sendRawData,

      menuAnchorEl,
      menuOpenNodeId,
      changingRegion,
    } = this.state;

    let nodesRows = null;
    const logRows = [];
    const regionList = Utils.isHomeyCloud(this.props.homey) ? REGIONS_CLOUD : REGIONS_PRO_2023 

    const RouteNode = (props) => {
      return <span
        key={props.nodeId}
        className={'RouteNode'}
        style={{
          backgroundColor: this._getNodeColor(props.nodeId),
          transform: hoverNodeId === props.nodeId ? 'scale(1.35)' : 'scale(1.0)',
        }}
        onMouseLeave={() => this.setState({ hoverNodeId: null })}
        onMouseEnter={() => this.setState({ hoverNodeId: props.nodeId })}
      >{props.nodeId}</span>
    }

    if (Array.isArray(nodes)) {
      if (zw_topology !== null && this.state.zw_secure_nodes) {
        nodesRows = nodes.map((nodeId, nodeIndex) => {

          const topologyObj = zw_topology && zw_topology[nodeId];

          const priorities = {
            'lwr': 'Last working route',
            'nlwr': 'Prev. last working route',
            'false': 'Unknown'
          }

          const isCoordinator = (nodeId === rootNodeId);
          const securityKey = this.state.zw_secure_nodes[nodeId];
          const isFailed = this.state.zw_failed_nodes.includes(nodeId);
          const isBeingRemoved = this.state.zw_removing_nodes[nodeId];
          const isBeingTested = this.state.zw_testing_nodes[nodeId];
          const isBeingHealed = this.state.zw_healing_nodes[nodeId];
          const onlineStats = stats[`node_${nodeId}_online`];
          const networkStats = stats[`node_${nodeId}_network`] || {
            tx: '-',
            tx_queued: '-',
            tx_ok: '-',
            tx_err: '-',
            rx: '-',
          };

          const flags = [];

          if (isCoordinator)
            flags.push('Coordinator');

          if (securityKey)
            flags.push(`Secure (${securityKey})`);

          if (typeof onlineStats === 'boolean')
            flags.push(onlineStats ? 'Online' : 'Offline');

          if (priorityNodeId === nodeId)
            flags.push('Priority');

          if (noAckNodes && noAckNodes.includes(nodeId))
            flags.push('NoAck');

          if (zw_battery_nodes && zw_battery_nodes.includes(nodeId))
            flags.push('Battery');

          if (isFailed)
            flags.push('Unreachable');

          let routeEls = [];
          let route = topologyObj && topologyObj.props.route;
          if (route) {
            route = route.slice(0, route.indexOf(0));
            route.push(nodeId);
            route.unshift(rootNodeId);
            route.forEach((routeNodeId, index) => {
              if (index !== 0) routeEls.push(<span key={`${index}-sep`}>—</span>);
              routeEls.push(<RouteNode key={routeNodeId} nodeId={routeNodeId} />);
            })
          }

          return (
            <TableRow
              key={nodeIndex}
              hover={true}
            >
              <TableCell>
                <RouteNode nodeId={nodeId} />
              </TableCell>
              <TableCell>{(topologyObj && topologyObj.name) || <em>Unknown Node</em>}</TableCell>
              <TableCell>{!isCoordinator && (
                (topologyObj && (priorities[topologyObj.props.priority])) || '-'
              )}
              </TableCell>
              <TableCell>{!isCoordinator && routeEls}</TableCell>
              <TableCell>{networkStats.tx_queued}</TableCell>
              <TableCell>{networkStats.tx}</TableCell>
              <TableCell>{typeof networkStats.tx === 'number' && (networkStats.tx_err + ' (' + (networkStats.tx === 0 ? '0' : Math.round(100 * networkStats.tx_err / networkStats.tx)) + '%)' || '-')}</TableCell>
              <TableCell>{networkStats.rx}</TableCell>
              <TableCell>{flags.length > 0 ? flags.join(', ') : '-'}</TableCell>
              <TableCell>
                {!isCoordinator && (
                  <IconButton
                    onClick={event => {
                      this.setState({
                        menuAnchorEl: event.currentTarget,
                        menuOpenNodeId: nodeId,
                      });
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
                <Menu
                  anchorEl={menuAnchorEl}
                  open={menuOpenNodeId === nodeId}
                  onClose={() => {
                    this.setState({
                      menuAnchorEl: null,
                      menuOpenNodeId: null,
                    })
                  }}
                >
                  <MenuItem button onClick={() => this._onSendBasicSet(nodeId, 0xFF)}>Send Basic On</MenuItem>
                  <MenuItem button onClick={() => this._onSendBasicSet(nodeId, 0x00)}>Send Basic Off</MenuItem>
                  <MenuItem button onClick={() => this._onSendTestFrame(nodeId)}>Send Test Frame</MenuItem>
                  <MenuItem button disabled={isBeingHealed} onClick={() => this._onHeal(nodeId)}>Heal</MenuItem>
                  <MenuItem button disabled={isBeingTested} onClick={() => this._testNode(nodeId)}>Test</MenuItem>
                  <MenuItem button disabled={!isFailed || isBeingRemoved} onClick={() => this._onRemove(nodeId)}>Remove</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          );
        });

      }
    }

    let filteredLog = log.filter(logItem => (!logFilter || logItem.log.toLowerCase().includes(logFilter)));
    filteredLog.forEach(logItem => {
      logRows.push((
        <TableRow
          hover={true}
          key={logItem.date + logItem.log}
          style={{
            height: 'auto',
          }}
        >
          <TableCell>
            <pre>{logItem.date}</pre>
          </TableCell>
          <TableCell style={{
            fontFamily: '"Roboto Mono", monospace',
          }}>
            {logItem.log}
          </TableCell>
        </TableRow>
      ));
    });

    return (
      <Page className="PageToolsZwave" cards={true}>
        <Card title="Nodes">
          <div style={{
            overflowX: 'auto',
            textAlign: 'center'
          }}>
            <Table
              padding="none"
              style={{
                minWidth: 1000,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>NodeID</TableCell>
                  <TableCell>Device</TableCell>
                  <TableCell colSpan="2">Route</TableCell>
                  <TableCell>Tx Queued</TableCell>
                  <TableCell>Tx Sent</TableCell>
                  <TableCell>Tx Error</TableCell>
                  <TableCell>Rx</TableCell>
                  <TableCell colSpan="2">Flags</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  zw_state !== null && zw_topology !== null
                    ? nodesRows
                    : <TableCell colSpan={10} style={{
                      textAlign: 'center',
                      padding: 24,
                    }}>
                      <CircularProgress />
                    </TableCell>
                }
              </TableBody>
            </Table>
          </div>
        </Card>

        <Card title="System Information">
          <TextField
            value={networkKeys.S0}
            label="Network Key S0"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          <TextField
            value={networkKeys.Unauthenticated}
            label="Network Key Unauthenticated"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          <TextField
            value={networkKeys.Authenticated}
            label="Network Key Authenticated"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          <TextField
            value={networkKeys.Access}
            label="Network Key Access"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          <TextField
            value={homeId ? homeId.toString(16).toUpperCase() : ''}
            label="Home Group ID"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          <TextField
            value={HW_REGIONS[hardwareRegion] || 'Unknown'}
            label="Hardware Region"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          { Utils.isHomeyProPlatformV1(this.props.homey) &&
          <TextField
            value={softwareRegion || 'Unknown'}
            label="Software Region"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />}
          {( Utils.isHomeyProPlatformV2(this.props.homey) || Utils.isHomeyCloud(this.props.homey)) &&
            <FormControl>
            <FormHelperText>Software Region</FormHelperText>
            <Select
              id="Country_select"
              margin="normal"
              style={{ width: 400 }}
              value={changingRegion || softwareRegion || 'Unknown'}
              onChange={this.handleChangeRegionSelect.bind(this)}
            >
              {Object.keys(regionList).map(key => (
                      <MenuItem value={key}>
                        {regionList[key]}
                      </MenuItem>
                    ))}
            </Select>
          </FormControl>
          }
          <br />
          <TextField
            value={currentCommand || ''}
            label="Current Command"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
        </Card>

        <Card title="Tools">
          <FormControl
            margin="normal"
            fullWidth={true}
          >
            <FormGroup row={true}>
              <Button
                variant="contained"
                disabled={currentCommand === 'removeNode'}
                onClick={this._onRemoveGeneric}
              >Remove Any Node</Button>
              &nbsp;
              {currentCommand === 'removeNode' && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this._onRemoveGenericAbort}
                >Abort</Button>
              )}
            </FormGroup>
          </FormControl>
          { !Utils.isHomeyCloud(this.props.homey) && semver.gt(this.props.homey.softwareVersion, '7.4.1') &&
            <FormControl
              margin="normal"
              fullWidth={true}
            >
              <FormGroup row={true}>
                <Button
                  variant="contained"
                  disabled={currentCommand === 'removeNode'}
                  onClick={this._onRemoveUnknownNodes}
                >Remove Unknown Nodes</Button>
                &nbsp;
              </FormGroup>
            </FormControl>
          }
          <FormControl
            margin="normal"
            fullWidth={true}
          >
            <FormGroup row={true}>
              <Button
                variant="contained"
                onClick={this._onSendNIF}
              >Send Node Information Frame</Button>
            </FormGroup>
          </FormControl>

          <FormControl
            margin="normal"
            fullWidth={true}
          >
            <FormGroup row={true}>
              <TextField
                label="destNodeId,param1,param2,paramN"
                value={sendRawData}
                onChange={this._onSendRawDataChange}
                style={{ width: 400 }}
              />
              &nbsp;
              <Button
                size="small"
                variant="contained"
                onClick={this._onSendRawData}
              >Send Raw Data</Button>
            </FormGroup>
          </FormControl>

          <FormControl
            margin="normal"
            fullWidth={true}
          >
            <FormGroup row={true}>
              <Button
                color="secondary"
                variant="contained"
                onClick={this._onFactoryReset}
              >Reset Z-Wave Network</Button>
            </FormGroup>
          </FormControl>
        </Card>
        { !Utils.isHomeyCloud(this.props.homey) &&
          <Card
            title="Log"
            toolbar={
              <React.Fragment>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={!!logEnabled}
                      onChange={this._onSetLogEnabled} />
                  }
                  label={logEnabled ? 'Logging Enabled' : 'Logging Disabled'}
                  labelPlacement="start"
                />

                <IconButton
                  onClick={this._onToggleAutoscroll}
                >
                  {logAutoscroll && <IconVerticalAlignBottom style={{ fill: '#00A5FB' }} />}
                  {!logAutoscroll && <IconVerticalAlignBottom style={{ fill: '#ccc' }} />}
                </IconButton>

                <CopyToClipboard
                  text={filteredLog.map(log => `[${log.date}] ${log.log}`).join('\n')}
                  onCopy={() => {
                    this.props.handleSuccess('Log copied to clipboard');
                  }}
                >
                  <IconButton tooltip="Copy to Clipboard">
                    <IconCopy />
                  </IconButton>
                </CopyToClipboard>
              </React.Fragment>
            }
          >

            <div
              style={{
                height: 400,
                overflowY: 'auto',
              }}
              ref={(el) => { this._logContainer = el; }}
            >
              <Table
                padding="none"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Time</TableCell>
                    <TableCell>Entry</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logRows}
                </TableBody>
              </Table>
            </div>

            <TextField
              className="FlexGrow"
              label="Filter"
              fullWidth={true}
              onChange={this._onFilterChange}
            />

          </Card>
        }
        <Card
          title="State"
          toolbar={
            <IconButton onClick={this._getState}>
              <IconRefresh />
            </IconButton>
          }
        >
          <ReactJson
            theme="google"
            src={this.state.zw_state || {}}
            collapsed={true}
            name={null} />
        </Card>
        <Dialog
          open={!!this.state.changingRegion} 
        >
          <DialogTitle>⚠Changing Z-Wave region</DialogTitle>
          <DialogContent>
            <strong>Read this information first before continuing</strong>
            <Typography variant="body1">
              Changing your Z-Wave region is only for testing purposes. If you do decide to use this feature, be aware of the local rules and regulations regarding the Z-Wave frequency you pick.
              Athom B.V. is not liable for any damage or legal issues that may arise from using this feature.
              Changing your Z-Wave region will cause all paired Z-Wave devices to lose their connection with Homey. Reverting this option will reconnect the devices with your Homey.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleChangeRegionDialogClose.bind(this)} color="default">
              Cancel
            </Button>
            <Button onClick={this.handleChangeRegionDialogConfirm.bind(this)} color="primary">
              Set New Region and reboot
            </Button>
          </DialogActions>
        </Dialog>

      </Page>
    );
  }
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsZwave, { version: '>=2.0.5' })))));
